.featured-products {
   display: flex;
   flex-direction: column;
}

.featured-products-title {
   color: var(--dark-main-color);
   
   margin: 0 auto 1rem;
   
   font-size: 2rem;
   font-weight: 500;
   letter-spacing: 0.1rem;
   text-align: center;
   line-height: 100%;

   z-index: 1;
}

@media only screen and (min-width: 768px) {
   .featured-products-title {
      font-size: 2.6rem;
   }
}