.modal-container {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;

   background-color: rgba(0, 0, 0, 0.5);

   backdrop-filter: blur(0.3rem);

   visibility: hidden;
   opacity: 0;

   padding-bottom: 8vh;

   z-index: var(--modal-index);
   
   transition: opacity 0.3s linear, visibility 0.3s linear;
}

@media only screen and (min-width: 768px) {
   .modal-container {
      padding-bottom: 0;
   }
}

.modal-container.active {
   visibility: visible;
   opacity: 1;
}

.modal-default {
   display: flex;
   justify-content: center;
   align-items: center;
}

.modal-container.active > .modal {
   transform: translateY(0);
}

.modal {
   width: 100%;
   height: 100%;

   padding: 0 0 3vh;

   transform: translateY(100%);

   transition: transform 0.3s linear;
}

@media only screen and (min-width: 768px) {
   .modal {
      padding-bottom: 0!important;
   }
}