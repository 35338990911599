.password-modal {
   width: 80%;

   padding: 1.5rem 2rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   border-radius: 2rem;

   box-shadow: var(--hud-shadow);
}

@media only screen and (min-width: 768px) {
   .password-modal {
      width: 40%;
      max-width: 50rem;

      padding: 1.5rem 6rem;
   }
}

.password-modal-title {
   font-weight: 500;
   text-align: center;
   line-height: 100%;

   color: var(--secondary-color);

   margin-bottom: 2rem;
}

.password-modal-separator {
   margin: 1rem 0;

   border-top: 0.1rem solid var(--light-grey);
}

.password-modal-input {
   display: flex;
   flex-direction: column;
}

.password-modal-input > p {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   margin: 0 0 0.3rem 0.5rem;

   color: var(--light-grey);

   transition: color 0.1s linear;
}

.password-modal-input > input {
   width: 100%;

   padding: 0.5rem 1rem;

   line-height: 100%;
   text-align: center;

   border: 0.1rem solid var(--light-grey);
   border-radius: 0.5rem;

   transition: color 0.1s linear, border-color 0.1s linear;
}

.password-modal-input:focus-within > p {
   color: var(--dark-main-color);
}

.password-modal-input:focus-within > input {
   color: var(--main-color);

   border-color: var(--main-color);
}

.password-modal-response {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-transform: uppercase;
   text-align: center;
   
   margin: 1.5rem 0;

   color: var(--green);
}

.password-modal-response.error {
   color: var(--secondary-color);
}

.password-modal-button {
   color: #FFFFFF;
   background-color: var(--dark-main-color);

   margin: 2rem auto 0;
   padding: 0.5rem 1rem;

   border-radius: 2rem;

   font-weight: 500;
   letter-spacing: 0.05rem;
}

.password-modal-button:disabled {
   background-color: var(--light-main-color);
}

@media only screen and (min-width: 768px) {
   .password-modal-button:not(:disabled) {
      cursor: pointer;

      transition: background-color 0.2s linear;
   }

   .password-modal-button:not(:disabled):hover {
      background-color: var(--main-color);
   }
}