.order-products-list {
   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;
}

.order-product {
   display: flex;
}

.order-product-images {
   width: 30%;

   display: flex;
   flex-direction: column;
}

@media only screen and (min-width: 768px) {
   .order-product-images {
      width: 20%;
   }
}

.order-product-image {
   width: 100%;

   display: flex;
}

.order-product-image.brand {
   width: 80%;

   margin: 0 auto;
}

@media only screen and (min-width: 768px) {
   .order-product-image.brand {
      width: 50%;
   }
}

.order-product-image > img {
   width: 100%;
}

.order-product-data {
   width: 70%;
   
   padding: 0.5rem 0;
   
   display: flex;
   flex-direction: column;
}

@media only screen and (min-width: 768px) {
   .order-product-data {
      width: 80%;

      padding: 1rem 2rem;
   }
}

.order-product-row {
   display: flex;
   align-items: center;
}

.order-product-col {
   display: flex;
   flex-direction: column;
}

.order-product-col * {
   line-height: 100%;
}

.order-product-col.grow {
   flex-grow: 1;
}

.order-product-col.data {
   margin-top: auto;
}

.order-product-name {
   text-transform: uppercase;

   margin-bottom: 0.5rem;
}

.order-product-details {
   color: var(--light-grey);

   font-size: 1.4rem;
   font-weight: 300;
}

.order-product-quantity {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .order-product-quantity {
      flex-direction: row;
      column-gap: 0.5rem;
   }
}

.order-product-quantity > p {
   width: 3rem;
   height: 3rem;

   border-radius: 50%;

   background-color: var(--input-grey);

   display: flex;
   align-items: center;
   justify-content: center;
}

.order-product-quantity > span {
   font-size: 1.2rem;
   font-weight: 300;

   color: var(--light-grey);
}

.order-product-info {
   display: grid;
   grid-template-columns: min-content auto;
   gap: 0.2rem 1rem;

   font-size: 1.4rem;
}

@media only screen and (min-width: 768px) {
   .order-product-info {
      grid-template-columns: auto min-content;
      gap: 0.5rem 1rem;
   
      font-size: 1.4rem;
   }
}

.order-product-info > span {
   text-align: end;
}

.order-product-info > span.subtotal {
   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .order-product-info > span.subtotal {
      margin-top: 0.5rem;
   }
}

.order-product-info > p {
   text-align: end;
}

.order-product-price {
   font-weight: 500;
   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .order-product-price {
      margin-top: 0.5rem;
   }
}