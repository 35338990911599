.searchbox-select {
   font-size: 1.4rem;

   flex: 1 0 0rem;
}

.searchbox-select > select {
   display: none;
}

.searchbox-select-custom {
   padding: 0.7rem 0;

   border-radius: 0.5rem;

   color: var(--grey);
   background-color: var(--input-grey);

   width: 100%;

   text-align: center;

   position: relative;

   transition: color 0.1s ease, background-color 0.1s ease;
}

.searchbox-select-header {
   display: flex;
   align-items: center;
   justify-content: center;

   position: relative;

   -webkit-user-select: none; /* Safari */
   -ms-user-select: none; /* IE 10 and IE 11 */
   user-select: none; /* Standard syntax */
}

.searchbox-select-header > p {
   line-height: 100%;
}

.searchbox-select-header > svg {
   position: absolute;

   display: flex;
   align-items: center;

   right: 1rem;
}

.searchbox-select-body {
   max-height: 70vh;

   background-color: #FFFFFF;

   border: var(--input-border);
   border-radius: 0.5rem;

   flex-direction: column;

   top: 100%;
   right: 0;
   left: 0;

   z-index: 2;
}

@media only screen and (min-width: 768px) {
   .searchbox-select-body {
      display: none;
      
      max-height: 40vh;
   
      position: absolute;
   }
}

.searchbox-select-filter {
   padding: 0.1rem;

   flex-shrink: 0;
}

.searchbox-select-filter > input {
   font-size: 1.6rem;
   line-height: 100%;
   text-align: center;

   padding: 1.5rem 0;

   width: 100%;
   
   border: var(--input-border);
   border-radius: 0.5rem;

   color: var(--black);
}

@media only screen and (min-width: 768px) {
   .searchbox-select-filter > input {
      font-size: inherit;

      padding: 0.5rem 0;
   }
}

.searchbox-select-options {
   display: flex;
   flex-direction: column;

   overflow-y: auto;
}

.searchbox-select-options::-webkit-scrollbar {
   width: 0.7rem;
}

/* Handle */
.searchbox-select-options::-webkit-scrollbar-thumb {
   background-color: var(--light-grey);

   border-radius: 1rem;
}

/* Handle on hover */
.searchbox-select-options::-webkit-scrollbar-thumb:hover {
   background-color: var(--main-color);
}


.searchbox-select-option {
   font-size: 1.6rem;
   line-height: 100%;
   text-align: center;

   padding: 1.5rem 0.1rem;

   width: 100%;

   -webkit-user-select: none; /* Safari */
   -ms-user-select: none; /* IE 10 and IE 11 */
   user-select: none; /* Standard syntax */

   transition: background-color 0.2s ease;
}

@media only screen and (min-width: 768px) {
   .searchbox-select-option {
      font-size: inherit;

      padding: 0.5rem 0.1rem;

      color: var(--black);
   }
}

.searchbox-select-option.disabled {
   color: var(--light-grey);
}

.searchbox-select-option.selected {
   color: var(--main-color);
}

.searchbox-select-option:not(:last-child) {
   border-bottom: var(--input-border);
}

.searchbox-select-option:not(.disabled):hover {
   cursor: pointer;

   background-color: var(--soft-main-color);
}



/* Mobile */

.searchbox-select-modal {
   width: 80%;

   padding: 0.1rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 2rem;

   border-radius: 0.5rem;

   box-shadow: var(--hud-shadow);

   position: relative;
}



/* Behavior */

.searchbox-select-custom.open .searchbox-select-body {
   display: flex;
}

.searchbox-select-custom.open .searchbox-select-header > svg {
   transform: rotateZ(180deg);
}

.searchbox-select > select:not(:disabled) ~ .searchbox-select-custom {
   cursor: pointer;
}

.searchbox-select > select:disabled ~ .searchbox-select-custom {
   color: var(--light-grey);

   background-color: #F4F4F4;
}

.searchbox-select-custom.open {
   color: var(--black);
}