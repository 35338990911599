.product-btns {
   width: 100%;

   padding: 1.5rem 0;

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   border-radius: 0.5rem;

   display: flex;
   justify-content: space-evenly;
}

@media only screen and (min-width: 992px) {
   .product-btns {
      padding: 1.5rem 0 0;

      justify-content: space-around;
   }
}

.product-btn {
   width: 20%;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .product-btn {
      cursor: pointer;

      transition:  color 0.2s ease;
   }

   .product-btn:hover {
      color: var(--main-color);
   }

   .product-btn:hover > svg {
      color: var(--main-color);
   }
}

.product-btn > svg {
   width: 3.5rem;

   color: #FFFFFF;

   transition: color 0.2s ease;
}

.product-btn > p {
   font-size: 1rem;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .product-btn > p {
      font-size: 1.2rem;
   }
}