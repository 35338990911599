.file-uploader-container {
   padding: 1rem;

   border: 0.2rem dashed var(--input-grey);
   border-radius: 0.5rem;

   position: relative;

   transition: border-color 0.1s ease;
}

.file-uploader-container.is-dragging {
   border-color: var(--main-color);
}

.file-uploader-container > input {
   display: none;
}

.file-uploader {
   width: 100%;

   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 1rem;

   border: 0.1rem solid transparent;
   border-radius: 0.5rem;

   transition: border-color 0.1s ease;
}

@media only screen and (min-width: 768px) {
   .file-uploader {
      grid-template-columns: repeat(4, 1fr);
   }
}

.is-dragging .file-uploader {
   border-color: var(--main-color);
}

.file-uploader-file {
   width: 100%;

   display: flex;

   aspect-ratio: 1 / 1;

   position: relative;
}

.file-uploader-file > img {
   width: 100%;

   aspect-ratio: 1 / 1;

   object-fit: contain;
}

.file-uploader-file > button {
   position: absolute;
   top: -1rem;
   right: -1rem;

   width: 3rem;
   height: 3rem;

   border-radius: 50%;

   display: flex;
   align-items: center;
   justify-content: center;

   color: #FFFFFF;
   background-color: var(--light-secondary-color);

   font-size: 2rem;

   transition: background-color 0.1s ease;

   cursor: pointer;
}

.file-uploader-file > button:hover {
   background-color: var(--secondary-color);
}



/* Add button */

.file-uploader-add {
   width: 100%;

   aspect-ratio: 1 / 1;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
   align-items: center;
   justify-content: center;

   color: var(--light-grey);

   cursor: pointer;

   transition: color 0.1s ease;
}

@media only screen and (min-width: 768px) {
   .file-uploader-add {
      color: var(--input-grey);
   }
}

.file-uploader-add:hover {
   color: var(--main-color);
}

.file-uploader-add > div {
   width: 70%;
   height: 70%;

   border: 0.1rem solid var(--light-grey);
   border-radius: 50%;

   display: flex;
   justify-content: center;
   align-items: center;

   transition: border-color 0.1s ease;
}

@media only screen and (min-width: 768px) {
   .file-uploader-add > div {
      border: 0.1rem solid var(--input-grey);
   }
}

.file-uploader-add:hover > div {
   border-color: var(--main-color);
}

.file-uploader-add > div > svg {
   font-size: 5rem;

   transition: color 0.1s ease;
}



/* Placeholder */

.file-uploader-placeholder {
   grid-column: 1 / 5;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   row-gap: 1rem;

   aspect-ratio: 4 / 1;

   cursor: pointer;

   color: var(--light-grey);

   transition: color 0.1s ease;
}

.is-dragging .file-uploader-placeholder {
   color: var(--main-color);
}

.file-uploader-placeholder > p {
   font-size: 1.8rem;
   text-align: center;
}

.file-uploader-placeholder > span {
   font-size: 1.4rem;
   font-weight: 500;
   text-align: center;
}



/* Drag hover effect */

.file-uploader-hover {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   row-gap: 1rem;

   color: #FFFFFF;

   opacity: 0;
   visibility: hidden;
   background-color: transparent;

   backdrop-filter: blur(0.4rem);

   transition: opacity 0.1s ease, visibility 0.1s ease, background-color 0.3s ease;
}

.file-uploader-hover.is-dragging {
   opacity: 1;
   visibility: visible;
   background-color: rgba(0, 0, 0, 0.3);
}

.file-uploader-hover > p {
   font-size: 2.4rem;
   text-align: center;
}

.file-uploader-hover > span {
   font-size: 1.4rem;
   font-weight: 500;
   text-align: center;
}