.warranties-data {
   display: flex;
   flex-direction: column;
   row-gap: 2.5rem;

   width: 100%;

   position: relative;

   overflow: hidden;
}

@media only screen and (min-width: 768px) {
   .warranties-data {
      padding: 4rem 3rem;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem 5rem;

      background-color: #FFFFFF;

      border: var(--input-border);
      border-radius: 1rem;
   }
}

.warranties-group-container {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

@media only screen and (min-width: 768px) {
   .warranties-group-container {
      row-gap: 5rem;
   }
}

.warranties-form-title {
   grid-column: 1 / 3;

   font-size: 20px;
   font-weight: 600;
   text-align: center;

   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .warranties-form-title {
      font-size: 22px;
   }
}

.warranties-error {
   grid-column: 1 / 3;

   display: flex;
   align-items: center;
   justify-content: center;

   color: var(--secondary-color);

   font-weight: 500;
}

.warranties-tac {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   color: var(--light-grey);
}

.warranties-tac > ul {
   list-style: inside;
}

.warranties-tac > ul > li {
   font-size: 1.4rem;
   text-align: justify;
   text-justify: inter-word;
}

.warranties-tac > ul > li:not(:last-child) {
   margin-bottom: 0.5rem;
}

.warranties-tac > ul > li::marker {
   color: var(--main-color);
}

.warranties-tac > label {
   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   margin: 0 auto;
}

.warranties-tac > p {
   font-size: 1.8rem;
   font-weight: 500;
   text-align: center;

   color: var(--dark-main-color);
}

.warranties-buttons {
   grid-column: 1 / 3;

   display: flex;
   justify-content: space-between;
}

.warranties-button {
   color: var(--light-grey);
   background-color: var(--soft-grey);

   padding: 1rem 1.5rem;

   border: 1px solid var(--input-grey);
   border-radius: 0.5rem;

   transition: color 0.1s linear, background-color 0.1s linear;
}

.warranties-button:not(:disabled) {
   cursor: pointer;
}

.warranties-button:hover {
   color: var(--black);

   background-color: var(--lighter-grey);
}

.warranties-button[type=submit] {
   color: #FFFFFF;
   background-color: var(--main-color);
}

.warranties-button[type=submit]:hover {
   background-color: var(--dark-main-color);
}

.warranties-button[type=submit]:disabled {
   background-color: var(--light-main-color);
}