.product-card-container {
   display: flex;
   flex-direction: column;

   overflow: hidden;

   position: relative;

   height: 100%;
}

.product-card-imgs {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.product-card-img {
   display: flex;

   width: 100%;

   position: relative;

   aspect-ratio: 1 / 1;
}

.product-card-img > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
}

.product-card-brand-img {
   width: 60%;
}

.product-card-brand-img > img {
   width: 100%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}

.product-card-offer-tag {
   display: flex;

   position: absolute;
   top: 0;

   width: 30%;
}

.product-card-offer-tag > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
}

.product-card {
   display: flex;
   flex-direction: column;

   padding: 1rem;

   background-color: var(--dark-main-color);
   border-radius: 1rem;

   color: #FFFFFF;

   flex-grow: 1;

   box-shadow: var(--element-shadow);

   margin-bottom: 0.3rem;
}

.product-card > *:not(:last-child) {
   margin-bottom: 1rem;
}

.product-card-name {
   font-size: 1.4rem;
   font-weight: 500;
   text-transform: uppercase;

   width: 100%;

   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;

   overflow: hidden;
}

.product-card-price-container {
   display: flex;
   flex-direction: column;

   background-color: var(--soft-dark-main-color);

   padding: 0.5rem 0.7rem;
   margin-top: auto;

   border-radius: 1rem;
}

.product-card-prices {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.product-card-price-group {
   display: flex;
   flex-direction: column;
}

.product-card-price-text {
   font-size: 1.2rem;

   line-height: 100%;
}

.product-card-price {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   color: var(--input-grey);
}

.product-card-price.offer {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   color: #FFFFFF;
}

.product-card-price.offer-4x3 {
   font-size: 2.2rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--light-main-color);
}

.product-card-price > span {
   font-size: 1rem;
}

.product-card-price.offer > span {
   font-size: 1.2rem;
}

.product-card-credit {
   font-size: 1rem;
   font-weight: 300;
}

.product-card-credit > span {
   color: var(--yellow);

   font-weight: 500;
}

.product-card-info {
   font-size: 1.2rem;
   line-height: 100%;

   padding: 0.5rem 1rem;
   margin: 1rem auto 0.5rem;

   border-radius: 1rem;

   background-color: var(--dark-main-color);

   cursor: pointer;
}

@media only screen and (min-width: 768px) {
   .product-card-info {
      transition: background-color 0.1s linear;
   }

   .product-card-info:hover {
      background-color: var(--main-color);
   }
}

.product-card-input-container {
   display: flex;
   justify-content: space-between;
}

.product-card-input {
   width: 42.5%;

   display: flex;
   align-items: center;
}

.product-card-input > button {
   width: 20%;

   display: flex;
   align-items: center;
   justify-content: center;

   border-radius: 50%;
   
   cursor: pointer;
}

@media only screen and (min-width: 768px) {
   .product-card-input > button {
      flex-shrink: 0;
      
      width: 1.6rem;
      height: 1.6rem;

      border-radius: 50%;

      transition: color 0.2s linear, background-color 0.2s linear;
   }

   .product-card-input > button:hover {
      background-color: #FFFFFF;

      color: var(--dark-main-color);
   }
}

.product-card-input > button > svg {
   font-size: 1.2rem;
}

.product-card-input > input {
   min-width: 0;

   flex-grow: 1;

   background-color: transparent;

   color: #FFFFFF;

   text-align: center;
   
   appearance: textfield;
   -moz-appearance: textfield;
}

.product-card-input > input::-webkit-outer-spin-button,
.product-card-input > input::-webkit-inner-spin-button {
   appearance: text;
   -webkit-appearance: none;
   margin: 0;
}

.product-card-input-btn {
   width: 52.5%;

   display: flex;
   align-items: center;
   justify-content: center;
   column-gap: 0.5rem;

   background-color: var(--soft-dark-main-color);

   border-radius: 1rem;

   padding: 0.5rem;

   cursor: pointer;
}

@media only screen and (min-width: 768px) {
   .product-card-input-btn {
      transition: background-color 0.1s linear;
   }

   .product-card-input-btn:hover {
      background-color: var(--main-color);
   }
}

.product-card-input-btn > p {
   line-height: 100%;

   font-size: 1.4rem;
}

.product-card-input-btn > svg {
   font-size: 1.2rem;
}

.product-card-contact {
   width: 100%;

   margin-top: auto;

   display: flex;
   align-items: center;
   justify-content: center;
}

.product-card-contact-button {
   width: 100%;
   
   padding: 0.5rem;

   background-color: var(--main-color);

   border-radius: 2rem;

   display: flex;
   justify-content: center;
   align-items: center;

   font-size: 1.4rem;
   line-height: 100%;
   color: #333;
   cursor: pointer;
   
   transition: all 0.1s linear;
}

.product-card-contact-button:hover {
   background-color: var(--soft-dark-main-color);
   color: #fff;
}