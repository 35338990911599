.searchbox-index {
   z-index: calc(var(--modal-index) - 1);
}

.searchbox-container {
   display: flex;
   flex-direction: column;

   box-shadow: var(--element-shadow);

   border-radius: 2rem;
   
   pointer-events: all;
}

@media only screen and (min-width: 768px) {
   .searchbox-container {
      width: calc(100% / 3);

      min-width: 30rem;
   }
}

.searchbox-tabs {
   display: flex;

   width: 100%;

   /* border-bottom: 0.3rem solid var(--main-color); */
}

.searchbox-header {
   width: 100%;

   border-top-left-radius: 2rem;
   border-top-right-radius: 2rem;

   display: flex;
   align-items: center;
   justify-content: center;

   padding: 1rem 0;

   letter-spacing: 0.1rem;
   
   background-color: var(--dark-main-color);

   color: #FFFFFF;
}

.searchbox-tab {
   background-color: #FFFFFF;

   width: 50%;

   display: flex;
   align-items: center;
   justify-content: center;

   padding: 1rem 0;

   letter-spacing: 0.1rem;
}

.searchbox-tab.active {
   background-color: var(--main-color);

   color: #FFFFFF;
}

.searchbox-tab > p {
   line-height: 100%;
}

.searchbox {
   padding: 1rem 1.5rem;

   background-color: #FFFFFF;

   border-bottom-left-radius: 2rem;
   border-bottom-right-radius: 2rem;
}

.searchbox-row {
   display: flex;
   align-items: center;
   justify-content: space-between;

   column-gap: 1rem;
}

.searchbox-img {
   margin-left: auto;
   margin-right: auto;

   width: 100%;

   overflow: hidden;

   display: flex;

   border-radius: 2.5rem;

   position: relative;
}

.searchbox-img > img {
   width: 100%;

   aspect-ratio: 3 / 1;
   object-fit: fill;
}

.searchbox-img-text {
   position: absolute;

   color: #FFFFFF;

   width: 10rem;

   font-size: 3.2rem;
   font-weight: 700;
   line-height: 100%;
   text-align: center;
}

.searchbox-img-text.ancho {
   top: 10%;
   left: 1%;
}

.searchbox-img-text.alto {
   top: 10%;
   left: calc(50% - 5rem);
}

.searchbox-img-text.aro {
   top: 10%;
   right: 1%;
}

.searchbox-btn {
   width: 100%;

   border-radius: 1.5rem;
   
   padding: 0.7rem 0;
   
   color: #FFFFFF;
   background-color: var(--main-color);

   font-size: 1.4rem;
   letter-spacing: 0.1rem;

   cursor: pointer;
   
   transition: background-color 0.1s linear;
}

.searchbox-btn:disabled {
   background-color: var(--soft-secondary-color);
}



/* Desktop */

.searchbox-sides {
   display: flex;
   align-items: center;
   justify-content: space-between;
   column-gap: 10%;

   margin: 0 auto;

   width: var(--section-width);
   max-width: var(--section-max-width);

   z-index: 2;

   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;

   pointer-events: none;
}

.searchbox-desk-img {
   width: 100%;

   display: flex;
}

.searchbox-desk-img > img {
   width: 100%;

   aspect-ratio: 114 / 41;
   object-fit: fill;
}