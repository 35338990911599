.product-form {
   display: flex;
   align-items: stretch;
   column-gap: 1rem;

   margin-top: 1rem;
   padding: 1rem;
}

@media only screen and (min-width: 768px) {
   .product-form {
      margin-top: 0;
      padding: 2rem 3rem;

      align-items: center;
      justify-content: space-between;
   }
}

@media only screen and (min-width: 992px) {
   .product-form {
      margin-top: 2.5rem;
      padding: 0 2.5rem 0 0;
   }
}

.product-input {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;

   width: 50%;
}

@media only screen and (min-width: 768px) {
   .product-input {
      flex-direction: column-reverse;
      row-gap: 1rem;
      
      width: 40%;
   }
}

.product-input-content {
   display: flex;
   column-gap: 1rem;

   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .product-input-content {
      background-color: var(--soft-grey);

      border: var(--input-border);
      border-radius: 3rem;

      padding: 1rem 3rem;
   }
}

.product-input-content > input {
   width: 50%;
   background-color: var(--soft-grey);

   padding: 0.5rem 1rem;

   border: var(--input-border);
   border-radius: 2rem;

   font-size: 1.8rem;
   font-weight: 500;
   text-align: center;
   line-height: 100%;
}

.product-input-content > input::-webkit-outer-spin-button, 
.product-input-content > input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
.product-input-content > input[type=number] {
   appearance: textfield;
   -moz-appearance: textfield;
}

@media only screen and (min-width: 768px) {
   .product-input-content > input {
      background-color: transparent;

      padding: 0;

      border: none;
      border-radius: 0;

      font-size: 2.8rem;
      font-weight: 600;

      flex-grow: 1;
   }
}

.product-input-btn {
   width: 25%;

   display: flex;
   justify-content: center;
   align-items: center;
}

@media only screen and (min-width: 768px) {
   .product-input-btn {
      width: 3.4rem;
      height: 3.4rem;

      color: #FFFFFF;
      background-color: var(--main-color);

      border-radius: 50%;

      flex-shrink: 0;

      cursor: pointer;

      transition: background-color 0.3s linear;
   }

   .product-input-btn:hover {
      background-color: var(--dark-main-color);
   }
}

.product-input-unit {
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 100%;
   text-align: center;

   color: var(--light-grey);
}

@media only screen and (min-width: 768px) {
   .product-input-unit {
      font-size: 1.4rem;
   }
}

.product-add {
   width: 50%;

   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .product-add {
      flex-direction: column-reverse;
      row-gap: 1rem;
      
      width: 40%;
   }
}

.product-add-btn {
   color: #FFFFFF;
   background-color: var(--main-color);

   padding: 0.5rem 1rem;

   border-radius: 2rem;

   flex-grow: 1;

   width: 100%;

   display: flex;
   justify-content: center;
   align-items: center;

   position: relative;
}

@media only screen and (min-width: 768px) {
   .product-add-btn {
      cursor: pointer;

      border-radius: 3rem;

      height: 5.6rem;

      transition: background-color 0.3s linear;
   }

   .product-add-btn:hover {
      background-color: var(--dark-main-color);
   }
}

.product-add-btn > p {
   font-size: 1.8rem;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .product-add-btn > p {
      font-size: 2.4rem;
   }
}

.product-add-btn > svg {
   position: absolute;
   right: 1rem;

   width: 2.2rem;
}

@media only screen and (min-width: 768px) {
   .product-add-btn > svg {
      width: 3.6rem;
   }
}

.product-add-total {
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 100%;
   text-align: center;

   color: var(--light-grey);
}

@media only screen and (min-width: 768px) {
   .product-add-total {
      font-size: 1.4rem;
   }
}

.product-add-total span {
   color: var(--main-color);

   font-weight: 400;
}