.offices-list {
   width: 100%;

   padding: 1.5rem 3rem 4rem;
   margin-bottom: 2rem;

   background-color: #FFFFFF;

   border: 0.1rem solid var(--dark-main-color);
   border-radius: 1rem;

   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;

   position: relative;
}

.offices-select {
   width: 100%;

   padding: 0.7rem 1.5rem;
   
   color: #FFFFFF;
   background-color: var(--dark-main-color);

   font-size: 1.8rem;
   line-height: 100%;
   text-align: center;

   border-radius: 2rem;
   
   cursor: pointer;
}

.offices-select.open {
   color: #FFFFFF;
}

.offices-data {
   width: 100%;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;
}

.offices-data-title {
   font-weight: 300;
   line-height: 100%;

   color: var(--dark-main-color);
}

.offices-data-info {
   width: 100%;

   padding: 0.5rem 1rem;

   background-color: #F3F3F3;

   border-radius: 0.5rem;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.7rem;
}

.offices-data-info > span {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;
   text-align: center;

   color: var(--light-grey);
}

.offices-data-info > p {
   line-height: 100%;
   text-align: center;

   color: var(--grey);
}

.offices-data-info > p:not(:last-child) {
   margin-bottom: 0.5rem;
}

.offices-button {
   position: absolute;
   right: 0;
   bottom: -2rem;
   left: 0;

   display: flex;
   justify-content: center
}

.offices-button > button {
   color: #FFFFFF;
   background-color: var(--dark-main-color);

   padding: 1rem 3rem;

   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-transform: uppercase;
   letter-spacing: 0.1rem;

   border-radius: 2rem;

   transition: background-color 0.2s ease-in-out;
}

@media only screen and (min-width: 768px) {
   .offices-button > button {
      cursor: pointer;
   
      transition: background-color 0.2s ease-in-out;
   }

   .offices-button > button:hover {
      background-color: var(--main-color);
   }
}