.cart-button-container {
   position: fixed;
   right: 0;
   bottom: 9.5rem;
   left: 0;

   z-index: var(--hud-index);
}

@media only screen and (min-width: 768px) {
   .cart-button-container {
      display: none;
   }
}

.cart-button-container.empty {
   display: none;
}

.cart-button {
   width: 70%;

   color: #FFFFFF;

   background-color: var(--main-color);

   margin: 0 auto;
   padding: 1rem 2rem;

   border: var(--input-border);
   border-radius: 4rem;

   display: flex;
   align-items: center;
   justify-content: space-between;

   box-shadow: var(--element-shadow);
}

.cart-button::before {
   content: attr(data-href);
}

.cart-button-total {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   row-gap: 0.5rem;
}

.cart-button-total > p {
   font-weight: 300;
   line-height: 100%;

   color: var(--yellow);
}

.cart-button-total > span {
   font-size: 2.2rem;
   font-weight: 500;
   line-height: 100%;
}

.cart-button-action {
   display: flex;
   align-items: center;
   column-gap: 0.5rem;
}

.cart-button-action > p {
   line-height: 100%;
}

.cart-button-action > svg {
   font-size: 2.4rem;
   line-height: 100%;
}