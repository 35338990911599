.product-contact-container {
   padding: 1rem;

   display: flex;
   justify-content: center;
   align-items: center;
}

@media only screen and (min-width: 768px) {
   .product-contact-container {
      margin-top: 1rem;
      padding: 2rem 3rem;
   }
}

@media only screen and (min-width: 992px) {
   .product-contact-container {
      margin-top: 2.5rem;
      padding: 0 2.5rem 0;
   }
}

.product-contact-button {
   width: 100%;

   padding: 1rem 4rem;

   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 1rem;

   color: #FFFFFF;
   background-color: var(--main-color);

   border-radius: 2rem;
}

@media only screen and (min-width: 768px) {
   .product-contact-button {
      width: fit-content;

      padding: 1.5rem 4rem;

      border-radius: 3rem;

      cursor: pointer;

      transition: background-color 0.1s linear;
   }

   .product-contact-button:hover {
      background-color: var(--dark-main-color);
   }
}

.product-contact-button > svg {
   font-size: 2.4rem;
}

@media only screen and (min-width: 768px) {
   .product-contact-button > svg {
      font-size: 3rem;
   }
}

.product-contact-button > p {
   font-size: 2rem;
}

@media only screen and (min-width: 768px) {
   .product-contact-button > p {
      font-size: 2.4rem;
   }
}