.search-bar-container {
   padding: 2rem;

   display: flex;
   align-items: flex-start;
   justify-content: center;

   transform: translateY(100%);

   transition: transform 0.3s linear;
}

.modal-container.active > .search-bar-container {
   transform: translateY(0);
}

.search-bar {
   width: 100%;

   display: flex;
   flex-direction: column;
}

.search-bar-form {
   width: 100%;

   display: flex;

   position: relative;
}

.search-bar-form > input {
   width: 100%;

   padding: 1rem 7.1rem 1rem 2rem;

   border-radius: 3rem;
}

.search-bar-form > button {
   position: absolute;
   top: 0.1rem;
   right: 0.1rem;
   bottom: 0.1rem;

   width: 5rem;

   display: flex;
   align-items: center;
   justify-content: center;

   font-size: 2.2rem;

   color: #FFFFFF;
   background-color: var(--main-color);

   border-radius: 3rem;
}