.header-group {
   position: fixed;
   top: 0;
   left: 0;

   width: 100%;

   z-index: var(--header-index);
}

.header-container {
   display: flex;
   flex-direction: column;

   z-index: 1;
}

@media only screen and (min-width: 768px) {
   .header-container {
      background-color: #FFFFFF;

      box-shadow: 0 0.2rem 1rem 0.1rem rgba(0, 0, 0, 0.1);
   }
}

.header {
   padding: 1rem;
   
   display: flex;
   align-items: center;
   justify-content: space-between;

   width: var(--section-width);

   margin: 0 auto;
}

.header-btn {
   display: flex;
   align-items: center;
   justify-content: center;

   width: 3rem;
}

.header-btn > svg {
   font-size: 2.6rem;

   color: #FFFFFF;
}

.alt .header-btn > svg {
   color: var(--grey);
}

.header-brand {
   display: flex;
   width: 50%;
}

.header-brand > img {
   width: 100%;
}

.header-cart-btn {
   position: relative;
}

.header-cart-qty {
   position: absolute;
   top: -0.8rem;
   right: -1.5rem;

   background-color: var(--main-color);

   color: #FFFFFF;

   font-size: 1.2rem;

   line-height: 100%;

   display: flex;
   align-items: center;
   justify-content: center;

   height: 2.6rem;
   width: 2.6rem;

   border: 0.3rem solid var(--dark-main-color);
   border-radius: 50%;
}

.alt .header-cart-qty {
   border-color: #FFFFFF;
}



/* Desktop */

.header-desk {
   display: flex;
   flex-direction: column;

   width: var(--section-width);
   max-width: var(--section-max-width);

   padding: 1rem 0;
   margin: 0 auto;
}

.header-top {
   display: flex;
   align-items: center;
   justify-content: space-between;
   column-gap: clamp(4rem, 4rem, 6rem);
}

.header-top-brand {
   width: 15%;

   display: flex;

   flex-shrink: 0;
}

.header-top-brand > img {
   width: 100%;
}

.header-top-search {
   position: relative;

   display: flex;

   flex-grow: 1;
   
   max-width: 50rem;
}

.header-top-search > input {
   padding: 0.75rem 6rem 0.75rem 2rem;

   flex-grow: 1;

   font-size: 1.4rem;

   border: 0.1rem solid var(--dark-main-color);
   border-radius: 2rem;
}

.header-top-search > button {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;

   padding: 0 2rem;

   display: flex;
   align-items: center;

   color: var(--dark-main-color);
   
   font-size: 1.8rem;

   cursor: pointer;
}

.header-top-buttons {
   display: flex;
}

.header-top-button {
   padding: 1rem 2rem;

   display: flex;
   align-items: center;
   column-gap: 1rem;

   cursor: pointer;
}

.header-top-button.desktop-1 {
   display: none;
}

@media only screen and (min-width: 1240px) {
   .header-top-button.desktop-1 {
      display: flex;
   }
}

.header-top-button.desktop-2 {
   display: none;
}

@media only screen and (min-width: 1040px) {
   .header-top-button.desktop-2 {
      display: flex;
   }
}

.header-top-button:not(:last-child) {
   border-right: 0.1rem solid var(--input-grey);
}

.header-top-button > svg {
   font-size: 3rem;

   color: var(--dark-main-color);
}

.header-top-button-body {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.header-top-button-main {
   font-size: 1.4rem;

   color: var(--dark-main-color);

   transition: color 0.2s linear;
}

a.header-top-button-main:hover {
   color: var(--main-color);
}

.header-top-button-main.no-wrap {
   max-width: 14rem;

   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.header-top-button-secondary {
   font-size: 1.2rem;
   text-align: start;

   color: var(--light-secondary-color);

   cursor: pointer;

   transition: color 0.2s linear;
}

span.header-top-button-secondary {
   text-transform: lowercase;
}

span.header-top-button-secondary::first-letter {
   text-transform: uppercase;
}

a.header-top-button-secondary:hover,
button.header-top-button-secondary:hover {
   color: var(--secondary-color);
}

.header-top-cart-qty {
   position: absolute;
   top: 0.5rem;
   right: 1rem;

   width: 2rem;
   height: 2rem;

   display: flex;
   align-items: center;
   justify-content: center;

   font-size: 1.2rem;
   line-height: 100%;

   color: var(--dark-main-color);
   background-color: #FFFFFF;

   border: 0.1rem solid var(--dark-main-color);
   border-radius: 50%;
}

.header-nav {
   padding-top: 1rem;
   margin-top: 1rem;
   border-top: 0.1rem solid var(--input-grey);

   display: flex;
   align-items: center;
   justify-content: space-between;

   position: relative;
}

.header-nav-element {
   font-size: 1.4rem;

   color: var(--light-grey);

   transition: color 0.2s linear;
}

.header-nav-element:hover {
   color: var(--dark-main-color);
}

.header-nav-element.active {
   color: var(--main-color);
}

.header-nav-children {
   display: none;
   justify-content: space-between;

   width: 100%;

   margin-top: 1rem;
   padding-top: 1rem;

   border-top: 0.1rem solid var(--input-grey);

   background-color: #FFFFFF;
}

.header-nav-children.active {
   display: flex;
}