.whatsapp-container {
   display: flex;
   align-items: center;

   width: 100%;

   padding: 1rem 2rem;

   justify-content: space-evenly;

   background-color: var(--secondary-color);

   color: #FFFFFF;

   border-radius: 1rem;

   box-shadow: var(--element-shadow);
}

@media only screen and (min-width: 768px) {
   .whatsapp-container {
      padding: 2rem 4rem;
   }
}

.whatsapp-container > svg {
   font-size: 6rem;
}

@media only screen and (min-width: 768px) {
   .whatsapp-container > svg {
      font-size: 8rem;
   }
}

.whatsapp-container > p {
   font-size: 3rem;
}

.whatsapp {
   display: flex;
   flex-direction: column;
   align-items: center;
}

@media only screen and (min-width: 768px) {
   .whatsapp {
      row-gap: 1rem;
   }

   .whatsapp > p {
      font-size: 2rem;
   }

   .whatsapp > span {
      font-size: 2rem;
      font-weight: 300;
   }
}

.whatsapp-data {
   display: flex;
   flex-direction: column;
   align-items: center;

   line-height: 100%;

   margin-bottom: 0.5rem;
}

.whatsapp-data > p {
   font-size: 1.6rem;
}

.whatsapp-data > span {
   font-size: 1.4rem;

   font-weight: 300;
}

.whatsapp-phone {
   font-size: 2.2rem;
   font-weight: 500;
}

@media only screen and (min-width: 768px) {
   .whatsapp-phone {
      font-size: 3.6rem!important;
   }
}