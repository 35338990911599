.mobile-nav-container {
   position: fixed;
   left: 0;
   bottom: 0;
   right: 0;

   z-index: var(--high-hud-index);
}

.mobile-nav {
   height: 8vh;
   width: 100%;

   background-color: #FFFFFF;

   display: flex;
   justify-content: space-around;
   align-items: center;

   box-shadow: var(--hud-shadow);
}

.mobile-nav-element {
   color: var(--grey);

   height: 100%;
   width: 20%;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   row-gap: 0.6vh;

   position: relative;

   transition: color 0.1s linear;
}

.mobile-nav-element > svg {
   font-size: 3.2vh;
}

.mobile-nav-element > p {
   font-size: 1.25vh;
}

div.mobile-nav-element > p {
   text-transform: lowercase;
}

div.mobile-nav-element > p::first-letter {
   text-transform: uppercase;
}

.mobile-nav-element:not(.main-element)::before {
   content: '';

   position: absolute;
   top: 0;
   left: 10%;
   right: 10%;

   width: 80%;
   height: 0.5rem;

   background-color: transparent;

   transition: background-color 0.1s linear;
}

.mobile-nav-element.active:not(.main-element) {
   color: var(--main-color);
}

.mobile-nav-element.active:not(.main-element)::before {
   background-color: var(--main-color);
}

.mobile-nav-element.main-element {
   top: -1.5rem;

   height: unset;
}

.mobile-nav-element.main-element > svg {
   background-color: var(--grey);

   font-size: 4vh;

   height: 4vh;
   width: 4vh;

   padding: 1.4vh;

   display: flex;
   align-items: center;
   justify-content: center;

   border-radius: 50%;

   color: #FFFFFF;

   transition: background-color 0.1s linear;
}

.mobile-nav-element.main-element.active {
   color: var(--main-color);
}

.mobile-nav-element.main-element.active > svg {
   background-color: var(--main-color);
}