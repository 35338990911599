.results-grid {
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   gap: 5rem;

   max-width: 130rem;
   margin: 0 auto;

   grid-template-areas: "filter search search search search"
                        "filter result result result result"
                        "xxxxxx result result result result"
                        "xxxxxx pgtion pgtion pgtion pgtion";
}

.results-grid.search {
   grid-template-areas: "filter result result result result"
                        "filter result result result result"
                        "xxxxxx result result result result"
                        "xxxxxx pgtion pgtion pgtion pgtion";
}

.area-result {
   grid-area: result;
}

.area-search {
   grid-area: search;
}

.area-filter {
   grid-area: filter;
}

.area-pgtion {
   grid-area: pgtion;
}