.bread-crumb {
   padding: 0.1rem 0.5rem;

   border-radius: 0.7rem;

   /* display: inline; */

   width: 100%;

   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
}

@media only screen and (min-width: 768px) {
   .bread-crumb {
      padding: 0.5rem 1rem;

      display: inline;

      border: var(--input-border);
      
      background-color: #FFFFFF;
   }
}

.bread-crumb-element {
   display: inline;

   color: var(--main-color);

   font-size: 1.4rem;
   font-weight: 400;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .bread-crumb-element {
      font-size: 1.6rem;
   }
}

.bread-crumb-element > a,
.bread-crumb-element > p {
   display: inline;
   line-height: 100%;
}

.bread-crumb-element:not(:first-child) {
   margin-left: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .bread-crumb-element:not(:first-child) {
      margin-left: 1rem;
   }
}

.bread-crumb-separator {
   font-size: 1.2rem;

   margin-left: 0.5rem;

   color: var(--black);
}

@media only screen and (min-width: 768px) {
   .bread-crumb-separator {
      margin-left: 1rem;
   }
}

.bread-crumb-element:last-child {
   color: var(--light-grey);

   font-weight: 300;
}