.checkout-header {
   display: flex;
   justify-content: space-between;
   align-items: center;

   width: 80%;
   margin: 0 auto;

   position: relative;
}

.checkout-header-step {
   width: 4.5rem;
   height: 4.5rem;

   border: 0.2rem solid var(--dark-secondary-color);
   border-radius: 50%;

   display: flex;
   justify-content: center;
   align-items: center;

   color: var(--dark-secondary-color);
   background-color: #FFFFFF;

   z-index: 2;

   transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@media only screen and (min-width: 768px) {
   .checkout-header-step {
      width: 5.5rem;
      height: 5.5rem;

      color: var(--light-grey);
      background-color: var(--input-grey);

      border: none;

      position: relative;
   }
}

.checkout-header-step.active {
   color: #FFFFFF;
   background-color: var(--dark-secondary-color);
}

@media only screen and (min-width: 768px) {
   .checkout-header-step.active {
      color: #FFFFFF;
      background-color: var(--dark-main-color);
   }
}

.checkout-header-step > p {
   font-size: 2.2rem;
   font-weight: 500;
   line-height: 90%;

   display: flex;
}

.checkout-header-step > span {
   display: none;
}

@media only screen and (min-width: 768px) {
   .checkout-header-step > span {
      position: absolute;
      bottom: -2.5rem;

      display: flex;

      font-size: 1.4rem;
      line-height: 100%;
      letter-spacing: 0.05rem;
      white-space: nowrap;

      color: var(--light-grey);

      transition: color 0.3s linear;
   }

   .checkout-header-step.active > span {
      color: var(--dark-main-color);
   }
}

.checkout-header-progress {
   position: absolute;
   top: 27.5%;
   right: 0;
   bottom: 27.5%;
   left: 0;
}

.checkout-header-progress-bar {
   height: 100%;
   width: 98%;

   margin: 0 auto;

   display: flex;

   background-color: #FFFFFF;
   border: 0.1rem solid var(--input-grey);
}

@media only screen and (min-width: 768px) {
   .checkout-header-progress-bar {
      background-color: var(--soft-grey);

      border-color: var(--soft-grey);
   }
}

.checkout-header-progress-thumb {
   background-color: var(--secondary-color);

   width: 0%;
   margin: -0.1rem 0;

   transition: width 0.3s linear;
}

@media only screen and (min-width: 768px) {
   .checkout-header-progress-thumb {
      background-color: var(--main-color);
   }
}

.checkout-header-progress-thumb.step-1 {
   width: 0%;
}

.checkout-header-progress-thumb.step-2 {
   width: 50%;
}

.checkout-header-progress-thumb.step-3 {
   width: 100%;
}