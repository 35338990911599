.body {
   display: flex;
   flex-direction: column;

   position: relative;

   min-height: 100vh;

   padding-bottom: 13vh;

   background-color: var(--body);
}

@media only screen and (min-width: 768px) {
   .body {
      padding-bottom: 0;
   }
}

.section {
   width: var(--section-width);
   max-width: var(--section-max-width);

   margin: var(--section-margin);
}

@media only screen and (min-width: 768px) {
   .section {
      margin-top: 7rem;
   }
}

.section-index {
   z-index: var(--section-index);
}

.colored-section {
   width: 100%;

   margin-top: 2rem;

   z-index: var(--section-index);

   background-color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .colored-section {
      padding: 2rem 0;
      margin-top: 10rem;
   }
}

.colored-section > .content {
   width: var(--section-width);
   max-width: var(--section-max-width);

   padding: 2rem 0;
   margin: 0 auto;

   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

@media only screen and (min-width: 768px) {
   .colored-section > .content {
      row-gap: 4rem;
   }
}

.colored-section > .content > .content-title {
   color: #FFFFFF;

   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
   letter-spacing: 0.1rem;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .colored-section > .content > .content-title {
      font-size: 2.6rem;
   }
}

.gradient-background {
   background: rgb(0,22,99);
   background: linear-gradient(180deg, var(--dark-main-color) 0%, var(--dark-main-color) 10%, rgb(139, 20, 20) 30%, var(--background-color) 60%);

   position: absolute;
   top: 0;
   right: 0;
   left: 0;

   height: 100vh;
}



/* Margins */

.mt-0 {
   margin-top: 0!important;
}

.mb-1 {
   margin-bottom: 1rem;
}

.mb-2 {
   margin-bottom: 2rem!important;
}

@media only screen and (min-width: 768px) {
   .mt-section-desk {
      margin-top: 4rem!important;
   }

   .mt-desk-0 {
      margin-top: 0!important;
   }

   .mt-desk-2 {
      margin-top: 2rem!important;
   }

   .mt-desk-3 {
      margin-top: 3rem!important;
   }
}



/* Paddings */

.py-0 {
   padding-top: 0;
   padding-bottom: 0;
}



/* Fonts */

.title {
   margin: var(--section-margin);

   color: var(--dark-main-color);
   
   font-size: 2.6rem;
   letter-spacing: 0.05rem;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .title {
      margin-top: 7rem;
   }
}



/* Displays */

.flex-column {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
}



/* Positions */

.p-relative {
   position: relative;
}



/* Backgrounds */

.bg-transparent {
   background-color: transparent;
}



/* Alerts */

body.swal2-shown:has(.no-scroll-alert) {
   overflow: hidden!important;
}