.brand-header {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .brand-header {
      width: 50%;
      max-width: 65rem;

      margin: 0 auto;
   }
}

.brand-header-img {
   width: 50%;

   display: flex;

   margin: 0 auto;
}

@media only screen and (min-width: 768px) {
   .brand-header-img {
      width: 60%;
   }
}

.brand-header-img > img {
   width: 100%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}