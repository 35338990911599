.warranties-order {
   display: flex;
   flex-direction: column;

   color: var(--light-grey);
}

@media only screen and (min-width: 768px) {
   .warranties-order {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
   }
}

.warranties-order-column {
   display: grid;
   grid-template-columns: min-content 1fr;
   grid-auto-rows: auto;
   column-gap: 1rem;
}

.warranties-order-column > b {
   font-weight: 500;
   white-space: nowrap;

   padding: 1rem 0;
}

.warranties-order-column > p {
   text-align: end;

   padding: 1rem 0;
}