
/* Mobile */

.results-filter-container-mobile {
   display: flex;
   column-gap: 2rem;
}

.results-filter-mobile {
   flex-basis: calc(50% - 1rem);

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   padding: 0.3rem 1.3rem;

   border: var(--input-border);
   border-radius: 2rem;
}

.results-filter-mobile > svg {
   width: 2.2rem;
}

.results-filter-mobile > p {
   flex-grow: 1;

   line-height: 100%;
   text-align: center;
   letter-spacing: 0.1rem;
}



/* Modals */


.results-modal {
   width: 80%;
   height: 80%;

   padding: 1.5rem 2rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   border-radius: 1rem;

   box-shadow: var(--hud-shadow);

   overflow-y: auto;
}

.results-modal-title {
   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   color: var(--dark-main-color);

   margin-bottom: 2rem;
}

.results-modal-subtitle {
   line-height: 100%;
   text-align: center;

   color: var(--main-color);

   margin-bottom: 2rem;
}

.results-modal-subtitle.m-top {
   margin-top: 3rem;
}

.results-modal-body {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   flex-grow: 1;

   overflow-y: auto;
}

.results-modal-attributes {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}



/* Desktop */

.results-filter-container-desktop {
   width: 100%;

   display: flex;
   flex-direction: column;
}

.results-filter-title {
   margin-bottom: 3rem;
   margin-left: 1rem;
}

.results-filter-title.m-top {
   margin-top: 3rem;
}

.results-filter-subtitle {
   margin-bottom: 1.5rem;

   color: var(--main-color);

   font-weight: 500;
   text-align: center;
}

.results-filter-subtitle.m-top {
   margin-top: 3rem;
}



/* Filter element (Dropdown of checkbox) */

.results-filter {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

}

.results-filter:not(:last-of-type) {
   margin-bottom: 1rem;
}

.results-filter-header {
   position: relative;

   padding: 1rem;

   display: flex;
   align-items: center;
   justify-content: center;

   background-color: var(--dark-main-color);

   border-radius: 0.5rem;

   cursor: pointer;

   transition: background-color 0.2s linear;
}

.results-filter-header:hover {
   background-color: var(--main-color);
}

.results-filter-header > p {
   color: #FFFFFF;
}

.results-filter-header > svg {
   position: absolute;
   right: 0;

   padding: 0 1rem;

   color: #FFFFFF;

   transition: transform 0.3s linear;
}

.results-filter-header.open > svg {
   transform: rotateZ(180deg);
}

.results-filter-body {
   overflow: hidden;

   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;

   height: 0;

   transition: height 0.3s linear;
}

.results-filter-header.open ~ .results-filter-body {
   height: 23rem;
}

.results-filter-input { 
   padding: 0 1rem;

   position: relative;
}

.results-filter-input > input {
   font-size: 1.6rem;
   line-height: 100%;
   text-align: center;

   padding: 0.5rem 2rem;

   width: 100%;
   min-width: 0;

   flex-grow: 0;
   
   border: var(--input-border);
   border-radius: 0.5rem;
}

.results-filter-input-clear {
   position: absolute;
   top: 0;
   right: 1rem;
   bottom: 0;

   display: flex;
   justify-content: center;
   align-items: center;

   width: 2rem;
   padding-right: 0.4rem;
   height: 100%;

   font-size: 1.2rem;

   color: var(--light-grey);
   opacity: 0.3;

   z-index: 2;

   transition: color 0.2s ease, opacity 0.2s ease;
}

.results-filter-input-clear.active {
   color: var(--secondary-color);
   opacity: 1;

   cursor: pointer;
}

.results-filter-content {
   padding: 0 1rem;

   overflow: auto;

   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;
}

.results-filter-content::-webkit-scrollbar {
   width: 1rem;
}

.results-filter-content::-webkit-scrollbar-track {
   background: transparent;
}

.results-filter-content::-webkit-scrollbar-thumb {
   background: #888;
   border-radius: 1rem;
}

.results-filter-content::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
}

/* Custom checkbox */

.results-filter-element > input {
   display: none;
}

.results-filter-element > label {
   width: 100%;

   display: flex;
   align-items: center;
   column-gap: 0.5rem;
}

.results-filter-element > label > span {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;
}

.results-filter-element > label > div {
   width: 2rem;
   height: 2rem;

   border-radius: 0.5rem;

   background-color: var(--input-grey);

   position: relative;
}

/* Funcionalidad de los checkbox */

.results-filter-element input:checked ~ label > div::after {
   content: '';

   position: absolute;
   top: 0.4rem;
   right: 0.4rem;
   bottom: 0.4rem;
   left: 0.4rem;

   background-color: var(--main-color);

   border-radius: 0.3rem;

   z-index: 1;
}



/* Select de ordenamiento */

.results-filter-select {
   text-align: center;

   width: 100%;

   border: 0.2rem solid var(--dark-main-color);
   border-radius: 0.5rem;

   padding: 0.7rem 1rem;
   margin-bottom: 1rem;

   cursor: pointer;
}

.results-filter-submit {
   width: 100%;

   margin-top: 1rem;
   padding: 1rem;

   color: #FFFFFF;
   background-color: var(--main-color);

   border-radius: 0.5rem;

   transition: background-color 0.2s ease;
}

.results-filter-submit:disabled {
   background-color: var(--light-main-color);
}

.results-filter-submit:not(:disabled):hover {
   cursor: pointer;

   background-color: var(--dark-main-color);
}



/* Price filter */

.results-filter-price {
   display: flex;
   flex-direction: column;

   margin-bottom: 2rem;
}

.results-filter-price.m-bot {
   margin-bottom: 1rem;
}

.results-filter-price > p {
   font-size: 1.2rem;
   line-height: 100%;
}

.results-filter-price-input {
   position: relative;
}

.results-filter-price-input > input {
   font-size: 1.6rem;
   line-height: 100%;
   text-align: center;

   padding: 0.7rem 2rem;

   width: 100%;
   min-width: 0;

   flex-grow: 0;
   
   border: var(--input-border);
   border-radius: 0.5rem;
}

.results-filter-price-input > input::placeholder {
   font-size: 1.4rem;
}

.results-filter-price-clear {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;

   display: flex;
   justify-content: center;
   align-items: center;

   width: 2rem;
   padding-right: 0.4rem;
   height: 100%;

   font-size: 1.2rem;

   color: var(--light-grey);
   opacity: 0.3;

   z-index: 2;

   transition: color 0.2s ease, opacity 0.2s ease;
}

.results-filter-price-clear.active {
   color: var(--secondary-color);
   opacity: 1;

   cursor: pointer;
}