.pagination {
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.pagination-pages-list {
   width: 100%;

   display: flex;
   column-gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .pagination-pages-list {
      justify-content: center;
      column-gap: 2rem;
   }
}

.pagination-page {
   width: calc(100% / 9);
}

@media only screen and (min-width: 768px) {
   .pagination-page {
      width: unset;

      min-width: 4rem;
   }
}

.pagination-page-button {
   width: 100%;

   padding: 0.9rem 0;

   display: flex;
   justify-content: center;

   border: 0.1rem solid var(--grey);
   border-radius: 0.5rem;

   color: var(--grey);

   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;

   transition: color 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;
}

@media only screen and (min-width: 768px) {
   .pagination-page-button {
      padding: 0.9rem 1rem;
   }

   .pagination-page-button:not(:disabled, .active) {
      cursor: pointer;
   }

   .pagination-page-button:not(:disabled, .active):hover {
      color: #FFFFFF;
      border-color: var(--dark-main-color);
      background-color: var(--dark-main-color);
   }
}

.pagination-page-button.active {
   color: #FFFFFF;
   background-color: var(--main-color);

   border-color: transparent;
}

.pagination-page-button:disabled {
   color: var(--light-grey);
   background-color: var(--input-grey);

   border-color: transparent;
}

.pagination-element {
   width: 25%;
}

@media only screen and (min-width: 768px) {
   .pagination-element {
      width: unset;

      min-width: 8.3rem;
   }
}

.pagination-button {
   width: 100%;

   padding: 0.9rem 0;

   display: flex;
   justify-content: center;

   border: 0.1rem solid var(--dark-main-color);
   border-radius: 0.5rem;

   color: var(--dark-main-color);

   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   transition: color 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;
}

@media only screen and (min-width: 768px) {
   .pagination-button {
      padding: 0.9rem 1rem;
   }

   .pagination-button:not(:disabled) {
      cursor: pointer;
   }

   .pagination-button:not(:disabled):hover {
      color: #FFFFFF;
      border-color: var(--dark-main-color);
      background-color: var(--dark-main-color);
   }
}

.pagination-button:disabled {
   color: var(--light-grey);
   background-color: var(--input-grey);

   border-color: transparent;
}