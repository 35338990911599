.stock-modal {
   width: 90%;
   max-height: 70%;

   padding: 1.5rem 2rem;

   border-radius: 1rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

@media only screen and (min-width: 768px) {
   .stock-modal {
      width: 60%;
   }
}

.stock-modal > h1 {
   font-size: 2.2rem;
   text-align: center;

   color: var(--dark-main-color);
   
   margin: 0;
}

.stock-modal-body {
   overflow-y: auto;

   flex-grow: 1;
}

.stock-modal-body::-webkit-scrollbar {
   width: 0.3rem;
}

@media only screen and (min-width: 768px) {
   .stock-modal-body::-webkit-scrollbar {
      width: 0.7rem;
   }
}

.stock-modal-body::-webkit-scrollbar-thumb {
   background-color: var(--light-grey);

   border-radius: 1rem;
}

.stock-modal-body::-webkit-scrollbar-thumb:hover {
   background-color: var(--main-color);
}

.stock-modal-footer {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   padding-top: 2rem;
}

.stock-modal-footer-message {
   text-align: center;
}

.stock-modal-footer-message > a {
   font-weight: 500;
   color: var(--main-color);

   transition: color 0.2s ease;
}

@media only screen and (min-width: 768px) {
   .stock-modal-footer-message > a:hover {
      color: var(--dark-main-color);
   }
}

.stock-modal-footer-buttons {
   display: flex;
   justify-content: space-evenly;
}

@media only screen and (min-width: 768px) {
   .stock-modal-footer-buttons {
      justify-content: center;
      column-gap: 2rem;
   }
}

.stock-modal-footer-button {
   padding: 1rem 1.5rem;

   color: #FFFFFF;
   background-color: var(--light-grey);

   border-radius: 0.5rem;

   transition: background-color 0.2s ease, color 0.2s ease;
}

@media only screen and (min-width: 768px) {
   .stock-modal-footer-button:hover {
      background-color: var(--grey);
   }
}

.stock-modal-footer-button.success {
   background-color: var(--main-color);
}

.stock-modal-footer-button.success:not(:disabled) {
   cursor: pointer;
}

@media only screen and (min-width: 768px) {
   .stock-modal-footer-button.success:not(:disabled):hover {
      background: var(--dark-main-color);
   }
}

.stock-modal-footer-button.success:disabled {
   background-color: var(--light-main-color);
}