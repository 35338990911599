.purchase-steps-container {
   display: flex;

   padding: 0 15%;

   overflow: hidden;

   width: auto;
}

@media only screen and (min-width: 768px) {
   .purchase-steps-container {
      padding: 0;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 5rem;
   }
}

.purchase-steps {
   width: 100%;
}

/* Slick */

.purchase-steps .slick-list {
   overflow: visible!important;
}

.purchase-steps .slick-track {
   display: flex!important;
   column-gap: 1rem;

}

.purchase-steps .slick-slide {
   height: inherit!important;
}

.purchase-steps .slick-slide > div {
   height: 100%!important;

   padding: 1rem 0;
}

/* End slick */

.purchase-step {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
   justify-content: space-around;

   padding: 0.5rem 1rem;

   background-color: var(--soft-main-color);

   border: var(--input-border);
   border-radius: 0.5rem;

   width: 100%;
   height: 100%;

   filter: blur(0.15rem);

   transition: filter 0.3s linear, box-shadow 0.3s linear;
}

@media only screen and (min-width: 768px) {
   .purchase-step {
      filter: blur(0);
   }
}

.purchase-steps .slick-active > div > .purchase-step {
   filter: blur(0);

   box-shadow: var(--element-shadow);
}

.purchase-step-row {
   display: flex;
   justify-content: space-between;
   align-items: center;
   column-gap: 1rem;
}

.purchase-step-number {
   font-size: 4.5rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--dark-main-color);
}

.purchase-step-title {
   font-size: 1.6rem;
   text-align: left;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .purchase-step-title {
      font-size: 2rem;
   }
}

.purchase-step-data {
   font-size: 1.4rem;
   line-height: 140%;

   color: var(--grey);

   width: 64%;
}

@media only screen and (min-width: 768px) {
   .purchase-step-data {
      font-size: 1.6rem;
   }
}

.purchase-step-img {
   width: 35%;
}

.purchase-step-img > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
}