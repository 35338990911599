.featured-brands-container {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

.featured-brands-title {
   color: var(--dark-main-color);

   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
   letter-spacing: 0.1rem;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .featured-brands-title {
      font-size: 2.6rem;
   }
}

.featured-brands {
   width: 100%;

   padding: 0 5%;

   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-auto-rows: 1fr;
   gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .featured-brands {
      padding: 0;

      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2rem;
   }
}

.featured-brand {
   width: 100%;
   height: 100%;
   min-height: 4rem;

   border-radius: 0.5rem;

   display: flex;
   align-items: center;
}

@media only screen and (min-width: 768px) {
   .featured-brand {
      max-width: 12rem;
      min-height: 7rem;
   }
}

.featured-brand > img {
   width: 100%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}