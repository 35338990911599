.order-log {
   display: flex;
   flex-direction: column;
   row-gap: 3rem;
}



/* Status */

.order-status {
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.order-status-steps {
   width: 100%;

   display: flex;
   justify-content: space-between;
   align-items: center;

   position: relative;

   margin-bottom: 2rem;
}

.order-status-step {
   width: 4rem;
   height: 4rem;

   border: 0.1rem solid var(--dark-main-color);
   border-radius: 50%;

   font-size: 2rem;

   color: var(--dark-main-color);
   background-color: #FFFFFF;

   display: flex;
   align-items: center;
   justify-content: center;

   position: relative;

   z-index: 1;

   transition: color 0.3s linear, background-color 0.3s linear;
}

.order-status-step::before {
   content: attr(data-text);

   position: absolute;

   font-size: 1.4rem;
   white-space: nowrap;

   color: var(--light-grey);
}

.order-status-step:nth-child(2n + 1)::before {
   top: -2rem;
}

.order-status-step:nth-child(2n)::before {
   bottom: -2rem;
}

.order-status-step.active::before {
   color: var(--dark-main-color);
}

.order-status-step.active[data-current="1"]::before {
   color: var(--main-color);
}

.order-status-step.active {
   color: #FFFFFF;
   background-color: var(--dark-main-color);
}

.order-status-bar {
   position: absolute;
   top: 25%;
   right: 2rem;
   bottom: 25%;
   left: 2rem;

   border: 0.1rem solid var(--light-grey);

   display: flex;
}

.order-status-bar-gauge {
   background-color: var(--main-color);
   width: 0;

   margin: -0.1rem 0;

   transition: width 0.3s linear;
}

.order-status-bar-gauge.step-2 {
   width: calc(100% / 5);
}

.order-status-bar-gauge.step-3 {
   width: calc((100% / 5) * 2);
}

.order-status-bar-gauge.step-4 {
   width: calc((100% / 5) * 3);
}

.order-status-bar-gauge.step-5 {
   width: calc((100% / 5) * 4);
}

.order-status-bar-gauge.step-6 {
   width: 100%;
}

.order-status-data {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.order-status-title {
   font-weight: 300;
   line-height: 100%;
}

.order-status-current {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--main-color);
}

/* Main data */

.order-data {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   padding: 1rem 0.5rem;
   margin: -1rem 0;

   border-top: 0.1rem solid var(--input-grey);
   border-bottom: 0.1rem solid var(--input-grey);
}

@media only screen and (min-width: 768px) {
   .order-data {
      padding: 2rem 1rem;
      margin: 0;
   }
}

.order-data-row {
   display: flex;
   justify-content: space-between;

   font-weight: 300;
}

@media only screen and (min-width: 768px) {
   .order-data-row {
      font-weight: 400;
   }
}

.order-data-row p {
   line-height: 100%;
}

.order-data-row span {
   line-height: 100%;
   font-weight: 400;
}

@media only screen and (min-width: 768px) {
   .order-data-row span {
      font-weight: 500;
   }
}

.order-data-row span.blue {
   color: var(--dark-main-color);
}

.order-data-row span.red {
   color: var(--dark-secondary-color);
}

/* Buttons */

.order-buttons-overflow {
   overflow: hidden;
}

.order-buttons {
   width: 100%;

   padding: 0 0.5rem;

   display: flex;
   justify-content: space-between;
   
   overflow-y: auto;

   -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */
}

.order-buttons::-webkit-scrollbar {
   display: none;  /* Safari and Chrome */
}

.order-button {
   flex-shrink: 0;

   padding: 0.5rem 1rem;

   border-radius: 2rem;

   font-size: 1.2rem;
   font-weight: 300;
   line-height: 100%;

   background-color: var(--input-grey);

   transition: color 0.1s linear, background-color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .order-button {
      padding: 0.7rem 1.5rem;

      font-size: 1.6rem;

      cursor: pointer;
   }

   .order-button:hover {
      color: #FFFFFF;
      background-color: var(--main-color);
   }
}

.order-button:not(:first-child) {
   margin-left: 0.5rem;
}

.order-button:not(:last-child) {
   margin-right: 0.5rem;
}

.order-button.active {
   color: #FFFFFF;
   background-color: var(--dark-main-color);
}

/* Products */

@import url('./order-product.css');

/* Place */

.order-location {
   padding: 2rem 2.5rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   border: var(--input-border);
   border-radius: 1rem;
}

@media only screen and (min-width: 768px) {
   .order-location {
      flex-direction: row;
      justify-content: space-between;
      row-gap: 0;
      column-gap: 3rem;
   }

   .order-location > * {
      max-width: calc(100% / 3);
   }
}

.order-location-element {
   display: flex;
   justify-content: space-between;
   column-gap: 1rem;

   font-size: 1.4rem;
   text-transform: capitalize;
}

@media only screen and (min-width: 768px) {
   .order-location-element {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      column-gap: 0;
      row-gap: 0.5rem;

      font-size: 1.6rem;
   }
}

.order-location-element > p {
   line-height: 100%;

   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .order-location-element > p.p-hidden {
      display: none;
   }
}

.order-location-element > span {
   font-weight: 500;
   line-height: 100%;

   text-align: end;
}

@media only screen and (min-width: 768px) {
   .order-location-element > span {
      text-align: center;
   }
}

@media only screen and (min-width: 768px) {
   .order-location-element > p.p-hidden ~ span {
      margin-top: 0.5rem;

      font-weight: 400;

      color: var(--main-color);
   }
}

.order-location-group {
   display: flex;
   flex-direction: inherit;
   row-gap: inherit;
   column-gap: inherit;
}

@media only screen and (min-width: 768px) {
   .order-location-group {
      flex-direction: column;
   }
}

/* Payment */

.order-payment {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

.order-payment-row {
   padding: 2rem 2.5rem;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   row-gap: 1rem;

   background-color: #FFFFFF;

   border-radius: 1rem;
   border: var(--input-border);
}

@media only screen and (min-width: 768px) {
   .order-payment-row {
      flex-direction: row;
   }
}

.order-payment-element {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

@media only screen and (min-width: 768px) {
   .order-payment-element {
      flex-direction: column;
      align-items: center;
      row-gap: 0.5rem;
   }
}

.order-payment-element > p {
   font-size: 1.4rem;
   line-height: 100%;

   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .order-payment-element > p {
      font-size: 1.6rem;
      text-align: center;
   }
}

.order-payment-element > span {
   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .order-payment-element > span {
      font-size: 1.6rem;
   }
}

.order-payment-element > span.yellow {
   color: #FFCC00;
}

.order-payment-element > span.green {
   color: var(--green);
}

.order-payment-element > span.red {
   color: var(--secondary-color);
}

.order-payment-buttons {
   width: 100%;
   
   display: flex;
   justify-content: flex-end;
}

.order-payment-button {
   font-size: 1.8rem;
   font-weight: 500;
   letter-spacing: 0.05rem;

   padding: 0.7rem 1.5rem;

   color: #FFFFFF;
   background-color: var(--main-color);

   border-radius: 0.5rem;

   cursor: pointer;

   transition: background-color 0.1s linear;
}

.order-payment-button:hover {
   background-color: var(--dark-main-color);
}

.order-payment-data {
   margin-top: 0;
   padding: 0;

   background-color: transparent;

   border: none;
   border-radius: none;
}

/* Comments */

.order-comments {
   padding: 2rem 2.5rem;

   background-color: #FFFFFF;

   border: var(--input-border);
   border-radius: 1rem;

   line-height: 100%;
}

.order-comments.empty {
   color: var(--light-grey);
}

/* Bills */

.order-bills {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

.order-bills-row {
   padding: 2rem 2.5rem;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   row-gap: 1rem;

   background-color: var(--soft-grey);

   border-radius: 1rem;
   border: var(--input-border);
}

@media only screen and (min-width: 768px) {
   .order-bills-row {
      flex-direction: row;
   }
}

.order-bills-element {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

@media only screen and (min-width: 768px) {
   .order-bills-element {
      flex-direction: column;
      align-items: center;
      row-gap: 0.5rem;
   }
}

.order-bills-element > p {
   font-size: 1.4rem;
   line-height: 100%;

   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .order-bills-element > p {
      font-size: 1.6rem;
      text-align: center;
   }
}

.order-bills-element > span {
   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .order-bills-element > span {
      font-size: 1.6rem;
   }
}