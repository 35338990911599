.footer-section {
   display: none;

   background-color: rgba(0, 0, 0, 1);
   background-repeat: no-repeat;
   background-position: center;
   background-attachment: fixed;
   background-size: cover;
   background-blend-mode: hue;
}

@media only screen and (min-width: 768px) {
   .footer-section {
      display: block;

      margin-top: 4rem;
   
      color: #FFFFFF;
   }
}

.footer-content {
   width: var(--section-width);
   max-width: var(--section-max-width);

   margin: 0 auto;
}



/* Footer */

.footer {
   padding: 3rem 0;

   display: grid;
   grid-template-columns: repeat(5, 1fr);
   gap: 2rem;
}

.footer-col {
   display: flex;
   flex-direction: column;
   row-gap: 3rem;
}

.footer-link {
   font-size: 1.4rem;
   font-weight: 300;

   transition: color 0.2s linear;
}

.footer-link:hover {
   color: var(--light-main-color);
}

.footer-brand {
   grid-column: span 2;

   gap: 4rem;
}

.footer-contact {
   display: flex;
   flex-direction: column;
}

.footer-contact-row {
   display: flex;
   align-items: center;
   column-gap: 2rem;

   transition: color 0.2s linear;
}

a.footer-contact-row:hover {
   color: var(--light-main-color);
}

.footer-contact-row:not(:first-child) {
   padding-top: 2rem;
}

.footer-contact-row:not(:last-child) {
   padding-bottom: 2rem;

   border-bottom: 0.2rem solid var(--light-main-color);
}

.footer-contact-row > svg {
   font-size: 2.4rem;

   min-width: 2.4rem;
}

.footer-contact-row > p {
   font-size: 1.4rem;
   font-weight: 300;
}

.footer-social {
   display: flex;
   justify-content: space-evenly;
}

.footer-social-element {
   font-size: 3rem;

   transition: color 0.2s linear;
}

.footer-social-element:hover {
   color: var(--light-main-color);
}

.footer-trust {
   display: flex;
   align-items: center;
   justify-content: center;
   column-gap: 1rem;
}

.footer-trust-image {
   width: 17rem;

   display: flex;
}

.footer-trust-image > img {
   width: 100%;
}

.footer-trust-body {
   width: calc(100% - 18rem);

   display: flex;
   flex-direction: column;
   
   font-size: 1.4rem;
}

.footer-trust-body > span {
   font-weight: 300;

   color: var(--light-grey);
}



/* Copyrights */

.footer-copy {
   padding: 1rem 0;

   display: flex;
   align-items: center;
   justify-content: space-between;
}

.footer-copy-brand {
   width: 15%;

   display: flex;
}

.footer-copy-brand > img {
   width: 100%;
}

.footer-copy > p {
   font-size: 1.4rem;
   font-weight: 300;
}