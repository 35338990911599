.mobile-menu-container {
   display: flex;
   justify-content: center;
   align-items: flex-end;

   width: 100%;

   transform: translateY(100%);

   transition: transform 0.3s linear;
}

.modal-container.active > .mobile-menu-container {
   transform: translateY(0);
}

.mobile-menu {
   width: 90%;

   margin: 0 auto;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.mobile-menu-header {
   width: 100%;

   display: flex;
   align-items: center;

   background-color: #FFFFFF;

   padding: 0.7rem 1.5rem;

   color: var(--dark-secondary-color);

   border-radius: 5rem;

   visibility: hidden;
   opacity: 0;

   box-shadow: var(--element-shadow);

   transition: opacity 0.3s linear, visibility 0.3s linear;
}

.mobile-menu-header.active {
   opacity: 1;
   visibility: visible;
}

.mobile-menu-header > button {
   font-size: 2rem;
}

.mobile-menu-header > p {
   flex-grow: 1;
   text-align: center;

   letter-spacing: 0.1rem;
   font-weight: 500;
}

.mobile-menu-body {
   display: flex;
   justify-content: space-between;
}

.mobile-menu-body-main {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   width: 90%;

   margin: 0 auto;

   transition: width 0.3s linear;
}

.mobile-menu-body-main.active {
   width: 4.5rem;

   margin: 0;
}

.mobile-menu-primary-element {
   display: flex;
   align-items: stretch;

   position: relative;

   width: 100%;
}

.mobile-menu-primary-element-icon {
   background-color: var(--dark-main-color);

   width: 4.5rem;
   height: 4.5rem;

   display: flex;
   align-items: center;
   justify-content: center;

   color: #FFFFFF;

   border-radius: 50%;

   z-index: 2;

   box-shadow: var(--element-shadow);

   transition: color 0.1s linear, background-color 0.1s linear;
}

.mobile-menu-primary-element-icon > svg {
   font-size: 2rem;

   line-height: 100%;
}

.mobile-menu-primary-element-text {
   position: absolute;
   top: 15%;
   right: 0;
   left: 0;

   height: 70%;

   background-color: #FFFFFF;

   padding-left: 4.5rem;

   display: flex;
   justify-content: center;
   align-items: center;

   border-radius: 2rem;

   color: var(--grey);

   box-shadow: var(--element-shadow);

   transition: color 0.1s linear, background-color 0.1s linear;
}

.mobile-menu-primary-element-text > p {
   width: 90%;

   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;

   text-align: center;

   line-height: 110%;

   letter-spacing: 0.05rem;
}

.mobile-menu-primary-element.active > .mobile-menu-primary-element-icon {
   background-color: var(--yellow);

   color: var(--black);
}

.mobile-menu-primary-element.active > .mobile-menu-primary-element-text {
   background-color: var(--main-color);

   color: #FFFFFF;
}

.mobile-menu-body-secondary {
   width: 0;

   overflow-y: auto;

   margin-top: 0.5rem;
   
   min-height: 0;
   
   transition: width 0.3s linear;
}

.mobile-menu-body-secondary-elements {
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;

   transform: translateY(-110%);

   overflow-y: auto;

   transition: transform 0.3s linear;
}

.mobile-menu-body-main.active ~ .mobile-menu-body-secondary {
   width: 100%;

   margin-left: 2rem;
}

.mobile-menu-body-main.active ~ .mobile-menu-body-secondary > .mobile-menu-body-secondary-elements {
   transform: translateY(0);
}

.mobile-menu-secondary-element {
   background-color: #FFFFFF;

   padding: 0.7rem 1.5rem;

   border-radius: 2rem;

   box-shadow: var(--element-shadow);

   transition: background-color 0.1s linear, color 0.1s linear;
}

.mobile-menu-secondary-element > p {
   width: 100%;

   line-height: 110%;

   text-align: center;

   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.mobile-menu-secondary-element.active {
   color: #FFFFFF;

   background-color: var(--main-color);
}