/* Input */

.order-tracking-form {
   display: flex;

   border: var(--input-border);
   border-radius: 0.5rem;

   overflow: hidden;

   margin: 2rem auto 0;
}

@media only screen and (min-width: 768px) {
   .order-tracking-form {
      width: 75%;
      max-width: 100rem;
   }
}

.order-tracking-form > input {
   flex-grow: 1;

   text-align: center;

   padding: 1rem 1.5rem;
}

.order-tracking-form > button {
   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   padding: 0 1rem;

   color: #FFFFFF;
   background-color: var(--main-color);
   
   cursor: pointer;

   transition: background-color 0.2s ease;
}

.order-tracking-form > button:disabled {
   background-color: var(--light-main-color);
}

.order-tracking-form > button:not(:disabled):hover {
   cursor: pointer;

   background-color: var(--dark-main-color);
}

.order-tracking-form > button > svg {
   font-size: 2rem;
}

.order-tracking-form > button > p {
   line-height: 100%;
}

.order-tracking-info {
   font-size: 2rem;
   font-weight: 300;
   text-align: center;

   color: var(--main-color);

   margin: 1rem auto 0;
}

@media only screen and (min-width: 768px) {
   .order-tracking-info {
      width: 40rem;
   }
}

.order-tracking-info.error {
   color: var(--secondary-color);
}

/* Data de la orden */

.order-tracking-section {
   display: flex;
   flex-direction: column;
   row-gap: 3rem;
}

@media only screen and (max-width: 767px) {
   .order-tracking-section {
      margin-top: 5rem;
   }
}

@media only screen and (min-width: 768px) {
   .order-tracking-section {
      width: 75%;
      max-width: 100rem;
      margin: 0 auto;
   }
}