.brand-search-section {
   max-width: 130rem;
}

.brand-search {
   position: relative;

   width: 100%;

   display: flex;
}

@media only screen and (min-width: 768px) {
   .brand-search {
      width: 60%;

      margin: 0 auto;
   }
}

.brand-search > input {
   background-color: #FFFFFF;

   padding: 0.75rem 5rem 0.75rem 2rem;

   border-radius: 2rem;

   width: 100%;

   color: var(--soft-dark-main-color);
   
   font-size: 1.8rem;
   font-weight: 300;
   text-align: center;

   border: var(--input-border);

   box-shadow: var(--element-shadow);
}

@media only screen and (min-width: 768px) {
   .brand-search > input {
      font-weight: 400;

      border: none;
   }
}

.brand-search > input::placeholder {
   color: var(--light-grey);

   font-weight: 300;
}

.brand-search > button {
   position: absolute;
   top: 0;
   right: 1rem;
   bottom: 0;

   color: var(--light-grey);

   font-size: 2.2rem;

   display: flex;
   align-items: center;
   justify-content: center;

   position: absolute;
   top: 0;
   right: 0;

   width: 5rem;
   height: 100%;
   
   cursor: pointer;

   transition: color 0.2s ease-in-out;
}

.brand-search > button:hover {
   color: var(--main-color);
}

.brands-section {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-template-areas: "main main main main";
   gap: 5rem;
}

@media only screen and (min-width: 768px) {
   .brands-section {
      grid-template-areas: "side main main main"
                           "side main main main";

      max-width: 130rem;
      margin: 0 auto;
   }
}

.brands-side {
   grid-area: side;

   display: none;
}

@media only screen and (min-width: 768px) {
   .brands-side {
      display: flex;
      flex-direction: column;
   }
}

.brands-sidebar {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;

   width: 100%;

   padding: 1.5rem 3rem;

   background-color: #FFFFFF;

   border: var(--input-border);
   border-radius: 1rem;
}

.brands-sidebar-title {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--dark-main-color);

   margin-bottom: 1rem;
}

.brands-sidebar-element {
   width: 100%;

   padding: 0.5rem 0;

   text-align: start;

   cursor: pointer;

   transition: color 0.2s linear;
}

.brands-sidebar-element:not(.active):hover {
   color: var(--light-main-color);
}

.brands-sidebar-element.active {
   color: var(--main-color);
}

.brands-main {
   grid-area: main;

   display: flex;
   flex-direction: column;
   row-gap: 3rem;
}

@media only screen and (min-width: 768px) {
   .brands-main {
      row-gap: 5rem;
   }
}

.brands-elements {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.brands-elements.hidden {
   display: none;
}

.brands-title {
   display: flex;
   align-items: center;
   column-gap: 0.5rem;
}

.brands-title > p {
   color: var(--dark-main-color);

   font-size: 1.8rem;
   line-height: 100%;
   letter-spacing: 0.05rem;
}

.brands-title > div {
   flex-grow: 1;
   border-top: 0.05rem solid var(--light-grey);
   border-bottom: 0.05rem solid var(--light-grey);
}

.brands-list {
   display: grid;
   grid-template-columns: repeat(3, minmax(0, 1fr));
   gap: 1.5rem;
}

@media only screen and (min-width: 768px) {
   .brands-list {
      grid-template-columns: repeat(6, minmax(0, 1fr));
   }
}

.brand-group { 
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   max-width: 100%;

   
}

.brand-group > p {
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;  
   overflow: hidden;

   font-size: 1.4rem;
   text-align: center;
   font-weight: 500;
   
   width: 100%;

   color: var(--dark-main-color);
}

.brand-element {
   width: 100%;
   height: 10rem;

   padding: 0.5rem;

   background-color: #FFFFFF;

   border: 0.05rem solid var(--dark-main-color);
   border-radius: 1rem;

   box-shadow: var(--element-shadow);

   display: flex;
   align-items: center;
   justify-content: center;
}

.brand-element > img {
   width: 100%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}
