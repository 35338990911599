.checkout-reminder {
   border-top: 0.1rem solid var(--input-grey);
   padding-top: 1.5rem;

   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;
}

.checkout-reminder > p {
   font-weight: 500;
   text-align: center;
   color: var(--dark-main-color);
}

.checkout-reminder-conditions {
   padding-left: 2rem;

   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.checkout-reminder-conditions > li {
   position: relative;
}

.checkout-reminder-conditions > li::before {
   content: '';

   position: absolute;
   top: 0.6rem;
   left: -1.2rem;

   width: 0.6rem;
   height: 0.6rem;

   border-radius: 50%;

   background-color: var(--main-color);
}