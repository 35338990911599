.offices {
   display: flex;
   flex-direction: column;
   row-gap: 3rem;
}

.offices-title {
   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   color: var(--grey);
}

.offices-container {
   display: flex;
   justify-content: space-between;
   column-gap: 4rem;

   max-width: 120rem;
   margin: 0 auto;
}

.offices-container > * {
   flex-basis: 55rem;
}



/* Map */

.offices-modal {
   width: 80%;
   height: 70%;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
   
   border: 0.1rem solid rgba(0, 0, 0, 0.25);
   border-radius: 2rem;
   
   box-shadow: var(--hud-shadow);
   
   overflow: hidden;
}

@media only screen and (min-width: 768px) {
   .offices-modal {
      width: 80%;
      max-width: 80rem;
      height: 90%;
      max-height: 60rem;
   }
}