.maintenance_container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: auto;
  border: 5px solid rgb(255, 0, 0);
  border-radius: 1rem;
  margin-top: 5rem;
  padding: 4rem;
  flex-wrap: wrap;
  text-align: center;
  background-color: #FFF;
  box-shadow: 2px 2px 6px #2222226a;
}

.maintenance_message {
  font-size: 25px;
  margin-top: 5rem;
}

.chn_logo {
  max-width: 150px;
  height: auto;
  border-radius: 1rem;
  transition: ease 200ms background;
}
.chn_logo:hover {
  background-color: rgba(25, 0, 255, 0.135);
}

.bridgestone_logo_container {
  width: 100%;
  height: auto;
  max-width: 400px;
}

.bridgestone_logo {
  width: 100%;
  height: auto;
}

.redirect_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}